module controllers {
    export module tariff {
        interface ICountryVATExceptionListCtrlScope extends ng.IScope {


        }

        interface ICountryVATExceptionListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class CountryVATExceptionListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "$timeout",
                '$uibModal',
                '$state',
                '$stateParams',
                '$transitions',
                'countryVATExceptionService',
                'tariffCountryService'
            ];

            allDirtyRows: Array<interfaces.tariff.ICountryVATException>;
            countryVATExceptionSearch: interfaces.tariff.ICountryVATExceptionSearch;

            IsLoading: boolean = false;
            ShowGrid: boolean = false;
            

            gridApi: uiGrid.IGridApi;
            searchAccordian: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;


            filterNames: string[] = ["EntityCode",
                "Code",
                "Description",
                "TypeClassification",
                "IsActive"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];

            constructor(private $scope: ICountryVATExceptionListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private $state: ng.ui.IStateService,
                private $stateParams: ICountryVATExceptionListParams,
                private $transitions: ng.ui.core.ITransition,
                public countryVATExceptionService: interfaces.tariff.ICountryVATExceptionService,
                public tariffCountryService: interfaces.tariff.ITariffCountryService,
            ) {
                this.IsLoading = true;
                this.loadVATExceptions();
                this.ShowGrid = true;
                this.IsLoading = false;
            }

            loadVATExceptions() {

                var params = {
                    connectSearch: this.countryVATExceptionSearch,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.countryVATExceptionList'
                },
                    () => {
                        return this.countryVATExceptionService.getCountryVATExceptionList().query(params,
                            (result: Array<interfaces.costing.ICostDestinationCountry>) => {
                                this.gvwCountryVATExceptionList.data = result;

                            if (result[0]) {
                                this.gvwCountryVATExceptionList.totalItems = result[0].NumRecs;
                            } else {
                                this.gvwCountryVATExceptionList.totalItems = 0;
                            }
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                
            }


            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                this.countryVATExceptionSearch 
                this.filterList = [];

                if (this.gridApi !== undefined) {
                    this.gridApi.grid.clearAllFilters(true, true, true).then(() => {
                        this.gridApi.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.countryVATExceptionSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.gridApi) {
                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.countryVATExceptionService.getCountryVATExceptionList().query(params, (result: Array<interfaces.tariff.ICountryVATException>) => {
                    this.IsLoading = false;

                    var currentDirtyRows: Array<interfaces.tariff.ICountryVATException> = [];

                    _.forEach(this.allDirtyRows, function (value) {
                        var row: interfaces.tariff.ICountryVATException = _.find(result, _.matchesProperty('Id', value.Id));

                        if (row) {
                            var i: number = _.indexOf(result, row);
                            result[i] = value;
                            currentDirtyRows.push(result[i]);
                        }
                    });

                    this.gvwCountryVATExceptionList.data = result;

                    if (result[0]) {
                        this.gvwCountryVATExceptionList.totalItems = result[0].NumRecords;
                    } else {
                        this.gvwCountryVATExceptionList.totalItems = 0;
                    }

                    if (currentDirtyRows.length > 0) {
                        this.$timeout(() => {
                            _.forEach(currentDirtyRows, (value) => {
                                this.gridApi.rowEdit.setRowsDirty(currentDirtyRows);
                            });
                        });
                    }


                    this.$timeout(() => {
                        if (this.gridApi) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwCountryVATExceptionList.data = [];
                    this.gvwCountryVATExceptionList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.countryVATExceptionSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {

                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    
                }

                this.countryVATExceptionService.getCountryVATExceptionListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwCountryVATExceptionList.data = [];
                    this.gvwCountryVATExceptionList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }


            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.gridApi.grid.getColumn(name).filters[0].term || this.gridApi.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.gridApi.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.gridApi.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.gridApi.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                var dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });

                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {

                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.tariff.ICountryVATException = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }

                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }


                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.gridApi = gridApi;

                this.gridApi.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.gridApi.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });
            }


            gvwCountryVATExceptionList: uiGrid.IGridOptions = {
                data: [],
                enableSorting: true,
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enablePinning: false,
                rowEditWaitInterval: -1,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                enableVerticalScrollbar: 1,
                useExternalPagination: true,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) },
                columnDefs: [{
                    name: "EDIT",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                    <div class="GridButton"> 
                        <button type="button" ng-click="grid.appScope.countryVATExceptionListCtrl.removeInlineCountryVATException(row.entity)" class="btn btn-warning btn-sm">
                            Delete
                        </button>
                    </div>`,
                    width: 55,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    headerCellTemplate: `
                    <div class="GridButton center-block"> 
                        <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.countryVATExceptionListCtrl.addInline()">
                            <span class="fa fa-plus"></span>
                        </button>
                    </div>`
                },{
                    name: "Country",
                    displayName: "* Country",
                    field: "Country",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 200,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.countryVATExceptionListCtrl.loadCountries()"></gts-dropdown>
                            </form>`,
                    filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "NationalMeasureCode",
                    displayName: "* Tariff Code",
                    field: "NationalMeasureCode",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    enableFiltering: true,
                    enableSorting: true,
                    width: 120,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="text" name="NationalMeasureCode-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor maxlength="20"  />
                                </p>
                            </form>`,
                   filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`,
                }, {
                    name: "VATRateFormula",
                    displayName: "* VAT Formula",
                    field: "VATRateFormula",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    enableFiltering: false,
                    enableSorting: false,
                    width: 120,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                        <form name="inputForm">
                            <p class="input-group-sm">
                                <input type="text" name="VATRateFormula-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.countryVATExceptionListCtrl.vatFormulaChange(row.entity)" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor />
                            </p>
                        </form>`,
                }, {
                    name: "VATRateCalculationFormula",
                    displayName: "VAT Calculation Formula",
                    field: "VATRateCalculationFormula",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    enableFiltering: false,
                    enableSorting: false,
                    width: 300,
                    cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    editableCellTemplate: `
                    <form name="inputForm">
                        <p class="input-group-sm">
                            <input type="text" name="VATRateCalculationFormula-{{MODEL_COL_FIELD.$$hashKey}}" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" class="form-control" ui-grid-editor  />
                        </p>
                    </form>`,
                }, {
                    name: "IsActive",
                    displayName: "Is Active",
                    field: "IsActive",
                    width: 75,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                        cellTemplate: '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.countryVATExceptionListCtrl.activeCheckBoxChanged(row.entity)">',
                    editableCellTemplate: `
                                    <form name="inputForm">
                                        <p class="input-group-sm">
                                            <input type="checkbox" ng-model="MODEL_COL_FIELD" ng-class="'colt' + col.uid" ui-grid-editor>
                                        </p>
                                    </form>`,
                    filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                                        </div>`,
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                    width: 38,
                    enableSorting: false,
                    enableCellEdit: false,
                    enableCellEditOnFocus: false,
                    enableColumnMenu: false,
                    enableFiltering: false,
                }]
            };

            removeInlineCountryVATException(countryVATException: interfaces.tariff.ICountryVATException) {
                if (countryVATException.Id > 0) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'tariff.countryVATExceptionList'
                    }, () => {
                        return this.countryVATExceptionService.removeCountryVATException().delete({
                            id: countryVATException.Id,
                        }, (result) => {
                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(result);
                                this.DoSearch();
                            });
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
                }
                else {
                    _.remove(this.gvwCountryVATExceptionList.data, (o) => {
                        return o === countryVATException;
                    });
                }
            }

            activeCheckBoxChanged(countryVATException: interfaces.tariff.ICountryVATException) {
                if (this.gridApi) {
                    this.gridApi.rowEdit.setRowsDirty([countryVATException]);
                }
            }

            vatFormulaChange(countryVATException: interfaces.tariff.ICountryVATException) {
                countryVATException.VATRateCalculationFormula = "";
                if (this.gridApi) {
                    this.gridApi.rowEdit.setRowsDirty([countryVATException]);
                }
            }

            addInline() {
                (<Array<any>>(this.gvwCountryVATExceptionList.data)).splice(0, 0, {
                    IsActive: true
                });
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownList().query(() => {

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            submit_click() {
                var dirtyRows: Array<interfaces.tariff.ICountryVATException>;
                dirtyRows = _.map(this.gridApi.rowEdit.getDirtyRows(), (value) => { return value.entity; });

                if (this.allDirtyRows) {
                    _.forEach(dirtyRows, (value) => {

                        //If a dirty row exists in the new dirty rows then overwrite the old one.
                        var row: interfaces.tariff.ICountryVATException = _.find(this.allDirtyRows, _.matchesProperty('Id', value.Id));

                        if (row) {
                            //Row exists with the same Id.
                            var i: number = _.indexOf(this.allDirtyRows, row);
                            this.allDirtyRows[i] = row;
                        }
                        else {
                            this.allDirtyRows = _.concat(this.allDirtyRows, value);
                        }

                    });
                }
                else {
                    this.allDirtyRows = dirtyRows;
                }

                if (this.allDirtyRows.length > 0) {

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'tariff.countryVATExceptionList'
                    },
                        () => {
                            //Get all the dirty rows and save them.
                            return this.countryVATExceptionService.saveCountryVATException().save(this.allDirtyRows, (result: interfaces.applicationcore.IMessageHandler) => {
                                this.generalService.displayMessageHandler(result);

                                if (!result.HasErrorMessage) {
                                    this.gridApi.rowEdit.setRowsClean(this.allDirtyRows);
                                    this.allDirtyRows = [];

                                    this.$timeout(() => {
                                        this.DoSearch();
                                    });
                                }

                                this.allDirtyRows = [];
                            }, (errorResponse) => {
                                this.allDirtyRows = [];
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                        });
                }
            }

            excelDownload_click() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.countryVATExceptionSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.gridApi) {
                    var grid = this.gridApi.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                var params = {
                    connectSearch: searchObject
                }

                this.IsLoading = true;
                this.countryVATExceptionService.getCountryVATExceptionListExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwCountryVATExceptionList.data = [];
                    this.gvwCountryVATExceptionList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

        }

        angular.module("app").controller("countryVATExceptionListCtrl", controllers.tariff.CountryVATExceptionListCtrl);
    }
}